<template>
  <footer class="home-footer">
    <div class="footer-content flex">
      <div class="brand">
        <div class="flex">
          <img :src="companyConfig.black_logo_url" alt="" class="logo">
          <div class="brand-name">{{ companyConfig.show_name }}</div>
        </div>
        <div class="des">全新一代AI大模型简历生成工具</div>
      </div>

      <div class="flex">
        <div class="menu-ul">
          <div class="ul-t">产品列表</div>
          <a class="li" href="/my-resume" target="blank">AI优化简历</a>
          <a class="li" href="/template-center" target="blank">职业模板</a>
          <a class="li" href="/template-center?type=school" target="blank">高校模板</a>
          <a class="li" href="/interview-helper" target="blank">面试助手</a>
        </div>

        <div class="menu-ul">
          <div class="ul-t">关于我们</div>
          <a class="li" href="/about-us" target="blank">公司介绍</a>
          <a class="li" href="/service-agreement" target="blank">用户协议</a>
          <a class="li" href="/user-privacy-terms" target="blank">隐私协议</a>
          <!-- todo: 更新了《用户增值服务协议》之后，把链接替换上 -->
          <a class="li" href="/" target="blank">用户增值服务协议</a>
        </div>

        <div class="menu-ul">
          <div class="ul-t">联系方式</div>
          <div class="li text">商务合作：13051181982</div>
          <div class="li text">公司地址：北京市海淀区中关村<br>南大街乙12号院</div>
        </div>
      </div>

      <div class="qrcode-box">
        <div class="qrcode">
          <img src="@/assets/img/kefu.png" alt="" class="img">
          客服
        </div>

        <div class="qrcode">
          <img src="@/assets/img/gongzhonghao.jpg" alt="" class="img">
          未来简历
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/store/useGlobalStore';
import { computed } from 'vue';

const globalStore = useGlobalStore();
  const companyConfig = computed(() => {
  return globalStore.companyConfig
})
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}

.home-footer {
  width: 100%;
  color: #1A1A1A;
  max-width: var(--content-width);
  min-width: var(--content-min-width);
  padding: 0 var(--content-padding) 0 var(--content-padding);
  border-top: 1px solid #E6E6E6;

  .footer-content {
    padding: 48px 0 64px;
    justify-content: space-between;

    .brand {
      font-weight: bold;

      .logo {
        height: 48px;
        width: auto;
        margin-right: 10px;
      }

      .brand-name {
        font-size: 16px;
      }

      .des {
        font-size: 12px;
        margin-top: 10px;
      }
    }

    .menu-ul {
      align-self: stretch;
      min-width: 164px;

      .ul-t {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 22px;
      }

      .li {
        display: block;
        font-size: 14px;
        line-height: 18px;
        color: #1A1A1A;

        &:not(.text):hover {
          color: var(--primary);
        }

        &+.li {
          margin-top: 8px;
        }
      }
    }

    .qrcode-box {
      display: flex;
    }

    .qrcode {
      text-align: center;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;

      &+.qrcode {
        margin-left: 64px;
      }

      .img {
        display: block;
        width: 113px;
        height: 113px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>