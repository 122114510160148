import request from './axios'

interface PostSendCodeParmas {
  captchaVerifyParam: string
  phone: string
}
interface ReqLoginParams {
  phone: string
  sms_code: number
  group_id?: string
}

//  发送验证码
export const postSendCode = request<PostSendCodeParmas>(`/api/user/send_code`, 'POST');

// 绑定手机号 发送验证码
export const sendCodeBindMobile = request<{ phone: string }>(`/api/user/user/send_code`, 'POST');

// 手机号验证码登录
export const postLogin = request<ReqLoginParams>(`/api/user/login`, 'POST');

// 第三方token的方式登录
export const thirdTokenLogin = request<{ client_id: string, token: string, source: string }>(`/api/user/token_login`, 'POST', { noError: true });

// 获取用户信息
export const getUserInfoLogin = request(`/api/user/user_info`, 'GET');

// 修改用户信息
export const updateUserInfo = request(`/api/user/user_info`, 'PUT', {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

// 退出登录
export const logout = request(`/api/user/logout`, 'POST');

// 获取登录二维码
export const getLoginQrcode = request<{ code: string, gid?: string }, { url: string }>(`/api/user/qrcode`, 'GET');

// 获取二维码登录结果
export const qrcodeLoginRes = request<{ code: string }>(`/api/user/wechat_login`, 'POST', { noError: true });

// 获取团体信息
export const getGroupInfo = request<{ gid: string, host: string }>(`/api/user/group`, 'GET');

// 获取团体二维码登录结果
export const qrcodeGroupLoginRes = request<{ code: string }>(`/api/user/group_login`, 'POST', { noError: true });

// 获取绑定微信二维码
export const getBindWxQrcode = request(`/api/user/user/band_qrcode`, 'GET');

// 加入组织 id 组织id, name 姓名，sno 学号
export const joinGroup = request<{ id: number, name: string, sno: string }>(`/api/user/add_group`, 'POST');

// 退出组织
export const outGroup = request(`/api/user/out_group`, 'POST');